/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { Link, graphql } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { rhythm, scale } from "../utils/typography";
import Comments from "../components/comments";
import TimetoRead from "../components/time-to-read";

const Paragraph = styled.p`
  ${scale(-1 / 5)};
  display: block;
  margin-bottom: ${rhythm(1 / 4)};
  margin-top: ${rhythm(-1)};
`;

const UnorderedList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`;

const constructUrl = (baseUrl, path) =>
  !baseUrl || !path ? null : `${baseUrl}${path}`;

// Single page template
const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;
  const { timeToRead, tags, canonicalUrl } = post.frontmatter;
  const { previous, next } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        imageUrl={constructUrl(
          data.site.siteMetadata.siteUrl,
          data.mdx.frontmatter.image?.childImageSharp?.fixed?.src
        )}
        imageAlt={data.mdx.frontmatter.imageAlt}
        canonicalUrl={canonicalUrl}
      />
      <h1
        css={`
          margin-block-start: 0;
        `}
      >
        {post.frontmatter.title}
      </h1>
      <Paragraph>
        {post.frontmatter.date}
        <TimetoRead time={timeToRead} />
      </Paragraph>
      <ul
        css={`
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
          margin-inline-start: 0;
          li {
            margin-block-end: 0;
          }
          li a {
            display: inline-block;
            padding: 0.3rem 0.4rem 0.2rem;
            border-radius: var(--border-radius-lg);
            border: 2px solid var(--primary-color);
            color: var(--text-color);
            font-size: 1rem;
            line-height: 1;
            text-decoration: none;
            box-shadow: none;
            margin-block: ${rhythm(1 / 4)};

            :active,
            :hover {
              transition: background-color 150ms, color 150ms;
            }

            :focus {
              outline: 2px solid hsla(var(--pallete-pink-70));
            }
            :hover {
              color: var(--white);
              background-color: var(--primary-color);
            }
          }
        `}
      >
        {tags?.map((tag) => (
          <li key={tag}>
            <Link to={`/tags/${tag}`}>{tag}</Link>
          </li>
        ))}
      </ul>
      <MDXRenderer>{post.body}</MDXRenderer>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <Bio
        hasProfile={false}
        css={{
          gridTemplateColumns: "auto 1fr",
          gridTemplateAreas: '"avatar info"',
          textAlign: "start",
        }}
      />
      <UnorderedList>
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </UnorderedList>
      <Comments />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        canonicalUrl
        timeToRead
        tags
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fixed(height: 600, width: 1200) {
              src
            }
            fluid(maxWidth: 700, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        # Add these
        imageAlt
      }
    }
  }
`;
